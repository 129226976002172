import { ThemeProvider } from "@material-ui/core";
import React from "react";
// import "react-perfect-scrollbar/dist/css/styles.css";
import DashboardLayout from "./components/DashboardLayout/";
// import "./mixins/chartjs";
import GlobalStyles from "./styles";
import theme from "./theme";

export default function Admin() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <DashboardLayout />
    </ThemeProvider>
  );
}
