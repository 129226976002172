import { Typography } from "@material-ui/core";
import React, { useCallback, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useToasts } from "react-toast-notifications";
import formatFileName from "../../util/formatFileName";
import DoneUploading from "./components/DoneUploading";
import LinearProgressWithLabel from "./components/LinearProgressWithLabel";
import { uploadFile } from "./service/uploadToS3";

const baseStyle = {
  justifyContent: "center",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  width: "80%",
  padding: "10px",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const StyledDropzone = (props) => {
  const {
    fileType,
    onFileDropRejected,
    maxSize,
    maxFiles,
    setUploadedMediaKeys,
    selectText,
    currFileNames,
    setCurrFileNames,
    handleDocumentUploadClose,
    setTotalDocumentsToUpload,
    setFileTypes,
  } = props;
  const { addToast } = useToasts();

  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  const updateUploadProgress = (percent, stats) => {
    if (Math.round(percent * 100) > Math.round(uploadProgress)) {
      setUploadProgress(Math.round(percent * 100));
    }
  };

  const uploadStarted = (_) => {
    setUploadProgress(0);
    setIsUploading(true);
  };

  const uploadCompleted = (xhr, awsObjectKey) => {
    setIsUploading(false);
    setUploadedMediaKeys((prevKeys) => [...prevKeys, awsObjectKey]);
    setTotalDocumentsToUpload((prevTotalDocuments) => prevTotalDocuments - 1);
  };

  const deleteFile = (fileName) => {
    const indexToRemove = currFileNames.indexOf(fileName);
    setCurrFileNames((prevFileNames) => {
      return prevFileNames.filter((_, index) => index !== indexToRemove);
    });
    setUploadedMediaKeys((prevKeys) => {
      return prevKeys.filter((_, index) => index !== indexToRemove);
    });
  };

  const uploadError = (mssg) => {
    addToast(`There was an when uploading one of the files: ${mssg}`, {
      appearance: "error",
    });
    setTotalDocumentsToUpload((prevTotalDocuments) => prevTotalDocuments - 1);
    setIsUploading(false);
  };

  const invalidFileName = (acceptedFiles) => {
    const set = new Set();

    for (const file of acceptedFiles) {
      const formattedFileName = formatFileName(file.name, false);
      if (set.has(formattedFileName)) {
        addToast(
          `Cannot upload two files of the same name at once: ${file.name}`,
          {
            appearance: "error",
          }
        );
        handleDocumentUploadClose();
        return true;
      } else {
        set.add(formattedFileName);
      }
    }

    return false;
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    if (invalidFileName(acceptedFiles)) return;

    setTotalDocumentsToUpload(acceptedFiles.length);
    for (const file of acceptedFiles) {
      const didNotError = await uploadFile(
        file,
        false,
        uploadStarted,
        updateUploadProgress,
        uploadCompleted,
        () => {},
        uploadError
      );
      if (didNotError) {
        const fileName = formatFileName(file.name, false);
        setCurrFileNames((oldFileNames) => [...oldFileNames, fileName]);
        setFileTypes((oldFileTypes) => ({
          ...oldFileTypes,
          [fileName]: file.type,
        }));
        //application/pdf
        //application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
      }
    }
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: fileType,
    disabled: false,
    onDropRejected: onFileDropRejected,
    onDrop,
    maxSize,
    maxFiles,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  let dropzone = null;
  if (!isUploading && currFileNames.length == 0) {
    dropzone = (
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <Typography>{selectText}</Typography>
      </div>
    );
  } else {
    dropzone = (
      <div>
        {currFileNames.map((name, index) => (
          <DoneUploading key={index} fileName={name} onDelete={deleteFile} />
        ))}
        {isUploading ? (
          <LinearProgressWithLabel value={uploadProgress} />
        ) : null}
      </div>
    );
  }

  return dropzone;
};

export default StyledDropzone;
