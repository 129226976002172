import axios from "axios";
import Cookies from "js-cookie";
import React from "react";
import ReactDOM from "react-dom";
import { pdfjs } from "react-pdf";
import { BrowserRouter } from "react-router-dom";

import App from "./App";

import "./index.css";

const apiURL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5000"
    : "https://prest-ai-backend.onrender.com";

axios.defaults.baseURL = apiURL;

axios.interceptors.request.use(
  (config) => {
    const token = Cookies.get(`user_auth_token`);
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.min.3.6.172.js";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
