import React from "react";
import "tailwindcss/dist/base.css";
import tw from "twin.macro";
import FastIconImage from "../../images/fast-icon.svg";
import { default as PrestAdminDemo } from "../../images/prest-ai-admin-demo.png";
import { default as macHeroScreenshotImageSrc } from "../../images/prest-sources-demo.png";
import ReliableIconImage from "../../images/reliable-icon.svg";
import SimpleIconImage from "../../images/simple-icon.svg";
import FAQ from "./components/faqs/SingleCol.js";
import Features from "./components/features/ThreeColWithSideImage.js";
import FeatureWithSteps from "./components/features/TwoColWithSteps.js";
import Footer from "./components/footers/ClubFooter.js";
import ContactUsForm from "./components/forms/ClubContactUs.js";
import Hero from "./components/hero/TwoColumnWithVideo.js";

export default function LandingPage() {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  const imageCss = tw`rounded-4xl`;
  return (
    <div style={{ height: "100%", overflowX: "hidden", overflowY: "hidden" }}>
      <Hero
        heading={<>Elevate M&A Due Diligence with AI</>}
        description="An advanced AI-powered tool which ingests and indexes data in all forms (PDFs, presentations, spreadsheets), and automates company Q&A and information requests during due diligence processes."
        imageSrc={PrestAdminDemo}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText="Get started"
        primaryButtonUrl="/#contact"
        watchVideoButtonText="Watch a demo"
      />
      <Features
        id="features"
        subheading={<Subheading id="features">Solution</Subheading>}
        heading={
          <>
            There's a better way than <HighlightedText>ctrl-f.</HighlightedText>
          </>
        }
        description="As M&A professionals ourselves, we understand the challenges and time pressures that come with the due diligence stage in a deal. Prest helps you reclaim dozens of hours spent manually navigating Q&A. Simply upload relevant files directly from your VDR, and ask away!"
        linkText=""
        cards={[
          {
            imageSrc: FastIconImage,
            title: "Intelligent Q&A",
            description:
              "Effortlessly populates your Q&A tracker by scanning files in your data room.",
          },
          {
            imageSrc: SimpleIconImage,
            title: "Specific Answers",
            description:
              "Extracts accurate and insightful data with exact sources cited.",
          },
          {
            imageSrc: ReliableIconImage,
            title: "Maximum Privacy",
            description:
              "All content is end to end encrypted to ensure access only to designated users.",
          },
          // {
          //   imageSrc: ShieldIconImage,
          //   title: "Secure Payments",
          //   description:
          //     "We handle payment processing for you so you can focus on creating.",
          // },
          // {
          //   imageSrc: CustomizeIconImage,
          //   title: "Customizable",
          //   description:
          //     "Fully customize the theme of your club to match your brand.",
          // },
          // {
          //   imageSrc: SupportIconImage,
          //   title: "24/7 Support",
          //   description:
          //     "We are here to provide whatever you need whenever you need it.",
          // },
        ]}
      />
      <FeatureWithSteps
        subheading={<Subheading>Workflow</Subheading>}
        heading={
          <>
            Automated <HighlightedText>answers.</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc={macHeroScreenshotImageSrc}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />
      {/* <Pricing
        subheading={<Subheading id="pricing">Pricing</Subheading>}
        heading={
          <>
            Simple & Reasonable <HighlightedText>Pricing.</HighlightedText>
          </>
        }
        description="We know you work hard to earn, so we keep our pricing affordable and transparent. No hidden fees, you pay what you see."
        plans={[
          {
            name: "Basic 10",
            price: "FREE",
            duration: null,
            mainFeature: "For Creators Getting Started",
            features: [
              "10 Members",
              "10 Exclusive Posts",
              "10 Feed Updates",
              "10 Giveaways",
              "Priority Support",
              "2.9% + $0.30 payment processing fees",
            ],
          },
          {
            name: "Unlimited Everything",
            price: "7%",
            duration: " of monthly income",
            mainFeature: "For Established Creators",
            features: [
              "Unlimited Members",
              "Unlimited Exclusive Posts",
              "Unimited Feed Updates",
              "Unlimited Giveaways",
              "Priority Support",
              "2.9% + $0.30 payment processing fees",
            ],
            featured: false,
          },
        ]}
      />  */}
      <FAQ
        subheading={<Subheading id="faq">FAQS</Subheading>}
        heading={
          <>
            Frequently Asked <HighlightedText>Questions</HighlightedText>
          </>
        }
        description={null}
        faqs={[
          {
            question: "How does it work?",
            answer:
              "Prest is powered by vector databases and large language models. We have proprietary techniques for indexing your data that make it easy to lookup.",
          },
          {
            question: "Is my data secure?",
            answer:
              "Yes, security is at the forefront of everything we do. We have strict data access policies internally, sign NDAs, and log every access to your data. We also provide you the option to host your own files and help you set that up. For detailed information on our security practices, visit https://prestcloud.com/security.",
          },
          {
            question: "Will my data be used to train models?",
            answer:
              "No, all the models we use are pretrained and your data is never used to train any models. Also, an entire file is never sent to a model, only parts relevent to the question being answered.",
          },
          {
            question: "How much does it cost?",
            answer:
              "Our pricing varies by customer usage. We work with customers to setup a pricing model that aligns best with their use cases. We also help all our customers setup success metrics, so they are able to consistently see the value they get from Prest.",
          },
        ]}
      />
      <ContactUsForm
        subheading={<Subheading id="contact">Get Started</Subheading>}
      />

      <Footer />
    </div>
  );
}
