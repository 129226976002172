import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";

const DialogConfirmation = (props) => {
  const {
    dialogTitle,
    dialogBody,
    loading,
    dialogOpen,
    handleDialogClose,
    handleCofirmation,
  } = props;

  return (
    <Dialog
      open={dialogOpen}
      onClose={handleDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h4" style={{ fontWeight: "bold" }}>
          {dialogTitle}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {dialogBody}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose} color="primary" disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={handleCofirmation}
          color="primary"
          autoFocus
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "Confirm"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default DialogConfirmation;
