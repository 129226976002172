import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Divider,
  InputAdornment,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import axios from "axios";
import React, { useState } from "react";
import {
  FileText as FileTextIcon,
  MessageCircle as MessageSquareIcon,
  Search as SearchIcon,
} from "react-feather";
import { useToasts } from "react-toast-notifications";
import Page from "../../../../../misc/Page";
import SourceCard from "./components/SourceCard";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  mainContainer: {
    display: "flex",
    // justifyContent: "space-between",
    flexDirection: "column",
    alignContent: "center",
    justifyContent: "flex-start",
    width: "100%",
    rowGap: "10px",
  },
  cardFlexBox: {
    flex: 1,
    width: "100%",
    height: "100%",
  },
  searchContainer: {
    gap: "20px",
  },
  textInput: {
    width: "100%",
    // borderStyle: "solid",
    borderWidth: "1px",
  },
}));

const Search = () => {
  const { addToast } = useToasts();
  const classes = useStyles();
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState("");
  const [sources, setSources] = useState([]);

  const handleSearchBarEnter = async (event) => {
    if (event.key === "Enter") {
      await searchCollection();
    }
  };

  const parseSource = (info) => {
    const ans = [];
    Object.values(info).forEach((i) => {
      ans.push({
        file: i.file_name,
        page: i.page_label,
        url: i.file_url,
        text: i.text,
        prestDocId: i.doc_id,
        fileType: i.file_type,
        sheetName: i.sheet_name,
      });
    });
    setSources(ans);
  };

  const searchCollection = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        "collection/query",
        {
          collection_id: 1,
          question: searchQuery,
        },
        {
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        setResult(response.data.final_response);
        parseSource(response.data.sources);
      }
    } catch {
      addToast("Something went wrong. Please try again.", {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setIsLoading(false);
      resetAll();
    }
  };

  const handleQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const resetAll = () => {
    setSearchQuery("");
  };

  const resultWithTitle = (
    <div>
      <Box display="flex" alignItems="center" mt={1} mb={2} gridGap={5}>
        <MessageSquareIcon size={22} strokeWidth={1.6} />
        <Typography>Response</Typography>
      </Box>
      <Card className={classes.cardFlexBox}>
        <CardContent>
          <div>{result}</div>
        </CardContent>
      </Card>
    </div>
  );

  const resultsSection = isLoading ? <CircularProgress /> : resultWithTitle;

  const sourcesWithTitle = (
    <div>
      <Box display="flex" alignItems="center" mb={2} gridGap={5}>
        <FileTextIcon size={22} strokeWidth={1.6} />
        <Typography>Sources</Typography>
      </Box>
      {sources.map((source) => (
        <SourceCard
          className={classes.cardFlexBox}
          fileUrl={source.url}
          fileName={source.file}
          filePage={source.page}
          relevantText={source.text}
          prestDocId={source.prestDocId}
          fileType={source.fileType}
          sheetName={source.sheetName}
        />
      ))}
    </div>
  );

  const sourceSection =
    sources.length == 0 || isLoading ? null : sourcesWithTitle;

  return (
    <Page className={classes.root} title="Search Documents">
      <Container className={classes.mainContainer}>
        <Card className={classes.cardFlexBox}>
          <CardContent>
            <Box
              display="flex"
              flexDirection="column"
              className={classes.searchContainer}
            >
              <Box display="flex" alignItems="center" flexDirection="row">
                <TextField
                  className={classes.textInput}
                  value={searchQuery}
                  name="Name"
                  variant="outlined"
                  onChange={handleQueryChange}
                  placeholder="Ask a question to all documents in this collection"
                  size="small"
                  onKeyDown={handleSearchBarEnter}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon size="20" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
          </CardContent>
          <Divider />
          <Box display="flex" justifyContent="flex-end" p={2}>
            <Button
              disabled={searchQuery == "" || isLoading}
              color="primary"
              variant="contained"
              onClick={searchCollection}
            >
              Search
            </Button>
          </Box>
        </Card>
        {resultsSection}
        {sourceSection}
      </Container>
    </Page>
  );
};

export default Search;
