import { Route, Routes } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import AdminContainer from "./components/Admin";
import DocumentDisplay from "./components/Admin/components/DashboardLayout/components/DocumentDisplay/DocumentDisplay";
import DocumentsListComponent from "./components/Admin/components/DashboardLayout/components/DocumentsList/Documents";
import TrackersListComponent from "./components/Admin/components/DashboardLayout/components/TrackersList/Trackers";

import Search from "./components/Admin/components/DashboardLayout/components/Search/Search";
import Settings from "./components/Admin/components/DashboardLayout/components/Settings/Settings";

import AdminLoginContainer from "./components/AdminLogin";
import LandingPageContainer from "./components/LandingPage";
import SecurityPage from "./components/LandingPage/SecurityPage";

import { AdminPrivateRoutes } from "./components/PrivateRoutes/AdminPrivateRoutes";
import AuthContextProvider from "./context/AuthContext";

import "./style.css";
import "./styles/globalStyles.css";

function App() {
  return (
    <ToastProvider
      autoDismiss={true}
      placement="bottom-center"
      transitionDuration={0}
    >
      <AuthContextProvider>
        <Routes>
          <Route element={<AdminPrivateRoutes />}>
            <Route path="/admin" element={<AdminContainer />}>
              <Route path="search" element={<Search />} />
              <Route path="documents" element={<DocumentsListComponent />} />
              <Route path="trackers" element={<TrackersListComponent />} />

              <Route
                path="documents/:documentId"
                element={<DocumentDisplay />}
              />
              {/* <Route path="users" element={<div>users</div>} /> */}
              <Route path="settings" element={<Settings />} />
            </Route>
          </Route>
          <Route path="/login" element={<AdminLoginContainer />} exact />
          {/* <Route path="/member-login" component={MemberLoginContainer} exact />
          <Route path="/admin-signup" component={AdminSignUpContainer} exact />
          <Route
            path="/member-signup"
            component={MemberSignUpContainer}
            exact
          /> */}
          <Route path="/" element={<LandingPageContainer />} exact />
          <Route path="/security" element={<SecurityPage />} exact />
          {/* <MemberPrivateRoute path="/club" component={ClubContainer} /> */}
          {/* <Route component={Error} /> */}
        </Routes>
      </AuthContextProvider>
    </ToastProvider>
  );
}

export default App;
