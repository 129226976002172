import { Box, Container, makeStyles } from "@material-ui/core";
// import { Box, Container } from "@mui/material";
// import { makeStyles } from "@mui/styles";
import React from "react";
import { useToasts } from "react-toast-notifications";
import Page from "../../../../../misc/Page";
import TrackersList from "./TrackersList";
import TrackersToolbar from "./TrackersToolbar";
import TrackerUpload from "./components/TrackerUpload";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const TrackersListComponent = () => {
  const { addToast } = useToasts();

  const classes = useStyles();
  const [trackerUploadOpen, setTrackerUploadOpen] = React.useState(false);
  const [trackerListSelection, setTrackerListSelection] = React.useState([]);
  const [trackersTableRefreshKey, setTrackersTableRefreshKey] =
    React.useState(0);

  const handleTrackerUploadOpen = () => {
    setTrackerUploadOpen(true);
  };
  const handleTrackerUploadClose = () => {
    setTrackerUploadOpen(false);
    setTrackerListSelection([]);
  };

  return (
    <Page className={classes.root} title="Customers">
      <Container maxWidth={false}>
        <Box>
          <TrackersToolbar
            handleTrackerUploadOpen={handleTrackerUploadOpen}
            trackerListSelection={trackerListSelection}
            setTrackerListSelection={setTrackerListSelection}
            setTrackersTableRefreshKey={setTrackersTableRefreshKey}
          />
          <TrackersList
            trackerListSelection={trackerListSelection}
            setTrackerListSelection={setTrackerListSelection}
            trackersTableRefreshKey={trackersTableRefreshKey}
            setTrackersTableRefreshKey={setTrackersTableRefreshKey}
          />
          <TrackerUpload
            trackerUploadOpen={trackerUploadOpen}
            handleTrackerUploadClose={handleTrackerUploadClose}
            setTrackersTableRefreshKey={setTrackersTableRefreshKey}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default TrackersListComponent;
