import axios from "axios";
import React, { useContext, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { AuthContext } from "../../context/AuthContext";
import AdminLogin from "./AdminLogin";

export default function AdminLoginContainer() {
  const [creds, setCreds] = useState({
    club: "",
    email: "",
    password: "",
  });

  const { loginAdmin, isAdminAuth } = useContext(AuthContext);
  const { addToast } = useToasts();

  const sendLoginRequestAdmin = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    try {
      const response = await axios.post(
        "login",
        {
          email: data.get("email"),
          password: data.get("password"),
        },
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        loginAdmin(response.data.access_token);
      }
    } catch {
      addToast("Something went wrong. Please try again.", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const actions = {
    sendLoginRequest: sendLoginRequestAdmin,
    setCreds,
  };

  return (
    <AdminLogin credentials={creds} loggedIn={isAdminAuth} actions={actions} />
  );
}
