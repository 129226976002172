import {
  Box,
  Button,
  InputAdornment,
  TextField,
  makeStyles,
} from "@material-ui/core";
import axios from "axios";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { Search as SearchIcon, Trash2 as TrashIcon } from "react-feather";
import { useToasts } from "react-toast-notifications";
import DialogConfirmation from "../../../DialogCofirmation/DialogConfirmation";

const useStyles = makeStyles((theme) => ({
  root: {},
  toolbarBox: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: 1,
  },
  searchTextField: {
    height: "40px",
    marginRight: "16px",
    backgroundColor: "#fff",
    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.2)",
    width: "350px",
  },
  uploadButton: {
    height: "40px",
  },
  deleteButton: {
    height: "40px",
    marginRight: "8px",
  },
}));

const DocumentsToolbar = ({
  className,
  handleDocumentUploadOpen,
  documentListSelection,
  setDocumentListSelection,
  setDocumentsTableRefreshKey,
  setDocumentSearchTerm,
  ...rest
}) => {
  const { addToast } = useToasts();

  const classes = useStyles();
  const [deleteConfirmOpen, setDeleteConfirmOpen] = React.useState(false);
  const [deleteLoading, setDeleteLoading] = React.useState(false);
  const [searchInput, setSearchInput] = React.useState("");
  const debounceTimeoutRef = React.useRef(null);

  const handleDeleteConfirmOpen = () => {
    setDeleteConfirmOpen(true);
  };

  const handleDeleteConfirmClose = () => {
    setDeleteConfirmOpen(false);
  };

  const handleDeleteConfirm = () => {
    deleteDocuments();
  };

  const handleSearch = (event) => {
    setSearchInput(event.target.value);
    clearTimeout(debounceTimeoutRef.current);
    debounceTimeoutRef.current = setTimeout(() => {
      setDocumentSearchTerm(event.target.value);
    }, 500);
  };

  const deleteDocuments = async () => {
    try {
      setDeleteLoading(true);
      const response = await axios.post(
        "document/delete",
        {
          ids: documentListSelection,
        },
        {
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        addToast("Successfully deleted documents!", {
          appearance: "success",
          autoDismiss: true,
        });
        setDocumentsTableRefreshKey((oldKey) => oldKey + 1);
      }
    } catch {
      addToast("Something went wrong. Please try again.", {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setDocumentListSelection([]);
      setDeleteConfirmOpen(false);
      setDeleteLoading(false);
    }
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box className={classes.toolbarBox}>
        <TextField
          placeholder="Search documents list..."
          variant="outlined"
          value={searchInput}
          onChange={handleSearch}
          className={classes.searchTextField}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon size="20px" />{" "}
              </InputAdornment>
            ),
            style: {
              borderRadius: "4px",
              height: "40px",
              padding: "10px",
              fontSize: "14px",
              color: "black",
            },
          }}
        />
        <Box display="flex" alignItems="center" mb={1}>
          {" "}
          <Button
            className={classes.deleteButton}
            startIcon={<TrashIcon />}
            onClick={handleDeleteConfirmOpen}
            disabled={documentListSelection.length == 0 || deleteLoading}
            variant="text"
          />
          <Button
            color="primary"
            variant="contained"
            onClick={handleDocumentUploadOpen}
            className={classes.uploadButton}
          >
            Upload
          </Button>
        </Box>
      </Box>
      <DialogConfirmation
        dialogTitle="Confirm Delete"
        dialogBody="Are you sure you want to delete these documents? This cannot be undone."
        loading={deleteLoading}
        dialogOpen={deleteConfirmOpen}
        handleDialogClose={handleDeleteConfirmClose}
        handleCofirmation={handleDeleteConfirm}
      />
    </div>
  );
};

DocumentsToolbar.propTypes = {
  handleDocumentUploadOpen: PropTypes.func,
  documentListSelection: PropTypes.array,
  setDocumentListSelection: PropTypes.func,
  setDocumentsTableRefreshKey: PropTypes.func,
};

export default DocumentsToolbar;
