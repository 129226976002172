import Evaporate from "evaporate";
import Cookies from "js-cookie";
import sha256 from "js-sha256";
import sparkMD5 from "spark-md5";
import formatFileName from "../../../util/formatFileName";

const apiURL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5000"
    : "https://prest-ai-backend.onrender.com";

const uploadFile = async (
  file,
  isPrivate,
  startedFunc,
  progressFunc,
  completedFunc,
  cancelledFunc,
  uploadErrorFunc
) => {
  const token = Cookies.get(`user_auth_token`);
  const uploader = await Evaporate.create({
    signerUrl: `${apiURL}/document/sign_s3`,
    aws_key: "AKIATCA3PDLTWRVNTVKE",
    awsRegion: "us-east-2",
    bucket: "prest-prod",
    cloudfront: true,
    computeContentMd5: true,
    cryptoMd5Method: (d) => btoa(sparkMD5.ArrayBuffer.hash(d, true)),
    cryptoHexEncodedHash256: sha256,
    xhrWithCredentials: true,
    signHeaders: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await uploader.add({
      file: file,
      name: formatFileName(file.name),
      contentType: file.type,
      progress: progressFunc, //(percent, stats) => console.log("Progress", percent, stats),
      complete: completedFunc, // (xhr, awsObjectKey) => console.log("Complete!", awsObjectKey),
      error: uploadErrorFunc, // (mssg) => console.log("Error", mssg),
      cancelled: cancelledFunc,
      started: startedFunc, // (fileKey) => console.log("Started", fileKey),
    });
    return true;
  } catch (error) {
    console.log(`Could not upload to S3: ${error.message}`);
    return false;
  }
};

export { uploadFile };
