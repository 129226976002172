import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

export function AdminPrivateRoutes() {
  const { isAdminAuth } = useContext(AuthContext);
  if (!isAdminAuth) {
    return <Navigate to="/login" />;
  }

  return <Outlet />;
}
