import { Box, Container, makeStyles } from "@material-ui/core";
// import { Box, Container } from "@mui/material";
// import { makeStyles } from "@mui/styles";
import React from "react";
import Page from "../../../../../misc/Page";
import DocumentsList from "./DocumentsList";
import DocumentsToolbar from "./DocumentsToolbar";
import DocumentUpload from "./components/DocumentUpload";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const DocumentsListComponent = () => {
  const classes = useStyles();
  const [documentUploadOpen, setDocumentUploadOpen] = React.useState(false);
  const [documentListSelection, setDocumentListSelection] = React.useState([]);
  const [documentsTableRefreshKey, setDocumentsTableRefreshKey] =
    React.useState(0);
  const [documentSearchTerm, setDocumentSearchTerm] = React.useState("");

  const handleDocumentUploadOpen = () => {
    setDocumentUploadOpen(true);
  };
  const handleDocumentUploadClose = () => {
    setDocumentUploadOpen(false);
    setDocumentListSelection([]);
  };

  return (
    <Page className={classes.root} title="Customers">
      <Container maxWidth={false}>
        <Box>
          <DocumentsToolbar
            handleDocumentUploadOpen={handleDocumentUploadOpen}
            documentListSelection={documentListSelection}
            setDocumentListSelection={setDocumentListSelection}
            setDocumentsTableRefreshKey={setDocumentsTableRefreshKey}
            setDocumentSearchTerm={setDocumentSearchTerm}
          />
          <DocumentsList
            documentListSelection={documentListSelection}
            setDocumentListSelection={setDocumentListSelection}
            documentsTableRefreshKey={documentsTableRefreshKey}
            setDocumentsTableRefreshKey={setDocumentsTableRefreshKey}
            documentSearchTerm={documentSearchTerm}
          />
          <DocumentUpload
            documentUploadOpen={documentUploadOpen}
            handleDocumentUploadClose={handleDocumentUploadClose}
            setDocumentsTableRefreshKey={setDocumentsTableRefreshKey}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default DocumentsListComponent;
