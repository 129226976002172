import { IconButton, makeStyles, Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  textContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  text: {
    alignSelf: "center",
  },
}));

const DoneUploading = ({ fileName, onDelete }) => {
  let classes = useStyles();
  return (
    <div className={classes.textContainer} key={fileName}>
      <Typography className={classes.text}>{fileName}</Typography>
      <IconButton
        onClick={() => {
          onDelete(fileName);
        }}
      >
        <DeleteIcon />
      </IconButton>
    </div>
  );
};

export default DoneUploading;
