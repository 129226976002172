import { default as React } from "react";
import styled from "styled-components";
import "tailwindcss/dist/base.css";
import tw from "twin.macro";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/dot-pattern.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

import Footer from "./components/footers/ClubFooter.js";
import Header from "./components/headers/light.js";

// const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-6/12 lg:pr-12 lg:pl-6 flex-shrink-0 text-center lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex flex-col justify-center`;

// const Heading = tw.h3`font-black text-xl md:text-3xl leading-snug max-w-xl`;
// const Paragraph = tw.p`my-5 lg:my-8 text-lg lg:text-base font-medium text-gray-600 max-w-lg mx-auto lg:mx-0`;

const Actions = tw.div`flex flex-col items-center sm:flex-row justify-center lg:justify-start mt-8`;
const PrimaryButton = tw.button`font-bold px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;
const SecondaryButton = styled.button`
  ${tw`mt-4 sm:mt-0 sm:ml-8 flex items-center text-secondary-300 transition duration-300 hocus:text-primary-400 focus:outline-none`}
  .playIcon {
    ${tw`stroke-1 w-12 h-12`}
  }
  .playText {
    ${tw`ml-2 font-medium`}
  }
`;
// const List = tw.ul`list-disc my-5 lg:my-8 text-lg lg:text-base font-medium text-black max-w-lg mx-auto lg:mx-0`;
const ListItem = tw.li`mb-2 `;

const PageContainer = tw.div`min-h-screen flex flex-col items-center`;

const ContentContainer = tw.div`max-w-5xl p-8`;

const Heading = tw.h1`text-4xl font-semibold mb-6 text-gray-800`;
const SubHeading = tw.h2`text-2xl font-semibold mb-4 text-gray-700`;
const SubHeading2 = tw.h3`text-xl italic mb-4 text-gray-700`;
const Paragraph = tw.p`text-lg text-gray-700 mb-4`;
const List = tw.ul`list-disc text-lg text-gray-700 mb-6 pl-6`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48`}
`;
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 top-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none fill-current text-primary-500 opacity-25 absolute w-32 h-32 left-0 bottom-0 transform translate-x-10 translate-y-10 -z-10`}
`;

export default function SecurityPage() {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  const imageCss = tw`rounded-4xl`;
  return (
    <div style={{ height: "100%", overflowX: "hidden", overflowY: "hidden" }}>
      <Header />
      <PageContainer>
        <ContentContainer>
          <Heading>Security at Prest</Heading>
          <SubHeading>Our Commitment to Your Safety</SubHeading>
          <Paragraph>
            This page outlines the security policies and controls implemented by
            Prest to safeguard customer data as we provide software for
            searching confidential documents. We understand the sensitive nature
            of the capital markets due diligence process and are committed to
            ensuring the privacy and security of our customers' data.
          </Paragraph>
          <SubHeading>Background</SubHeading>
          <Paragraph>
            Prest specializes in indexing and searching files using Large
            Language Models (LLMs) and vector databases. We convert all types of
            documents into highly efficient, searchable resources. Through
            advanced algorithms and intuitive search functionalities, Prest
            empowers companies to navigate and extract valuable insights from
            extensive datasets.
          </Paragraph>
          <Paragraph>
            At Prest, we understand the importance of securing sensitive data.
            Our robust security measures guarantee the confidentiality and
            integrity of the information processed. We employ advanced
            encryption techniques, stringent access controls, and continuous
            monitoring to safeguard your valuable data assets. With Prest, you
            can explore the full potential of your data while ensuring
            uncompromised security.
          </Paragraph>
          <SubHeading>Security Controls</SubHeading>
          <SubHeading2>Access Controls</SubHeading2>
          <List>
            <li>Role-based access control limits access to customer data</li>
            <li>
              Multi-factor authentication or enterprise SSO required for all
              user logins
            </li>
          </List>
          <SubHeading2>Encryption</SubHeading2>
          <List>
            <li>Data in transit protected by 256-bit TLS encryption</li>
            <li>Data at rest encrypted using 256-bit AES</li>
          </List>
          <SubHeading2>Data Handling</SubHeading2>
          <List>
            <li>Usage logging and monitoring to detect anomalies</li>
            <li>Data minimization limits collection to required info</li>
            <li>Contracts prohibit unauthorized data usage</li>
          </List>
          <SubHeading2>Infrastructure Security</SubHeading2>
          <List>
            <li>Monthly vulnerability scans on all systems</li>
            <li>
              Application security testing every release cycle through CI/CD
            </li>
            <li>Hosted in SSAE-18 & SOC 2 certified data center</li>
          </List>
          <SubHeading2>Compliance</SubHeading2>
          <List>
            <li>SOC 2 Type 1 (certification in progress)</li>
            <li>Scheduled internal and external audits</li>
          </List>
          <SubHeading>Customer Controls</SubHeading>
          <List>
            <li>Customers retain ownership and control of data</li>
            <li>Self-service data retrieval and deletion</li>
            <li>Option to set data retention periods</li>
          </List>
          <SubHeading>Customer Controls</SubHeading>
          <Paragraph>
            Ensuring customer trust remains our top priority, and we uphold
            stringent security standards in all our operations. For further
            information on our security policies or to request detailed
            technical insights into our security architecture, please don't
            hesitate to reach out to us at nishant@prestcloud.com. We welcome
            any inquiries you may have.
          </Paragraph>
        </ContentContainer>
        <DecoratorBlob />
        <DecoratorBlob1 />
        <DecoratorBlob2 />
      </PageContainer>

      <Footer />
    </div>
  );
}
