import { Box, Button, makeStyles } from "@material-ui/core";
import axios from "axios";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { Trash2 as TrashIcon } from "react-feather";
import { useToasts } from "react-toast-notifications";
import DialogConfirmation from "../../../DialogCofirmation/DialogConfirmation";

const useStyles = makeStyles((theme) => ({
  root: {},
  // importButton: {
  //   marginRight: theme.spacing(1),
  // },
  // exportButton: {
  //   marginRight: theme.spacing(1),
  // },
}));

const TrackersToolbar = ({
  className,
  handleTrackerUploadOpen,
  trackerListSelection,
  setTrackerListSelection,
  setTrackersTableRefreshKey,
  ...rest
}) => {
  // const { handleDocumentUploadOpen } = props;
  const { addToast } = useToasts();
  const classes = useStyles();
  const [deleteConfirmOpen, setDeleteConfirmOpen] = React.useState(false);
  const [deleteLoading, setDeleteLoading] = React.useState(false);

  const handleDeleteConfirmOpen = () => {
    setDeleteConfirmOpen(true);
  };

  const handleDeleteConfirmClose = () => {
    setDeleteConfirmOpen(false);
  };

  const handleDeleteConfirm = () => {
    deleteTrackers();
  };

  const deleteTrackers = async () => {
    try {
      setDeleteLoading(true);
      const response = await axios.post(
        "tracker/delete",
        {
          ids: trackerListSelection,
        },
        {
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        addToast("Successfully deleted trackers!", {
          appearance: "success",
          autoDismiss: true,
        });
        setTrackersTableRefreshKey((oldKey) => oldKey + 1);
      }
    } catch {
      addToast("Something went wrong. Please try again.", {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setTrackerListSelection([]);
      setDeleteConfirmOpen(false);
      setDeleteLoading(false);
    }
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box display="flex" justifyContent="flex-end" mb={1}>
        {/* <Button startIcon={<ExternalLinkIcon />} variant="text" />
        <Button startIcon={<PlusIcon />} variant="text" /> */}
        <Button
          startIcon={<TrashIcon />}
          onClick={handleDeleteConfirmOpen}
          disabled={trackerListSelection.length == 0 || deleteLoading}
          variant="text"
        />
        <Button
          color="primary"
          variant="contained"
          onClick={handleTrackerUploadOpen}
        >
          New Tracker
        </Button>
      </Box>
      <DialogConfirmation
        dialogTitle="Confirm Delete"
        dialogBody="Are you sure you want to delete these trackers? This cannot be undone."
        loading={deleteLoading}
        dialogOpen={deleteConfirmOpen}
        handleDialogClose={handleDeleteConfirmClose}
        handleCofirmation={handleDeleteConfirm}
      />
      {/* <Box mt={3}>
        <Card>
          <CardContent>
            <Box maxWidth={500}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                placeholder="Search customer"
                variant="outlined"
              />
            </Box>
          </CardContent>
        </Card>
      </Box> */}
    </div>
  );
};

TrackersToolbar.propTypes = {
  className: PropTypes.string,
  handleTrackerUploadOpen: PropTypes.func,
  handleDeleteClick: PropTypes.func,
  deleteDisabled: PropTypes.func,
  deleteLoading: PropTypes.func,
};

export default TrackersToolbar;
