import { Card, Link, makeStyles } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const trackerStatusToDisplay = (status) => {
  switch (status) {
    case "COMPLETED":
      return "Completed";
    case "UPLOADED":
      return "Uploaded";
    case "EXECUTING":
      return "Executing...";
    default:
      return "Uploaded";
  }
};

export default function TrackersList(props) {
  const {
    trackerListSelection,
    setTrackerListSelection,
    trackersTableRefreshKey,
    setTrackersTableRefreshKey,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const { addToast } = useToasts();

  const parseAndSetRows = (data) => {
    const parsedRows = data.map((tracker) => {
      return {
        id: tracker.id,
        trackerName: tracker.name,
        status: trackerStatusToDisplay(tracker.status),
        uploadedBy: tracker.created_by_user.email,
        dateUploaded: new Date(tracker.created_at),
      };
    });
    setRows(parsedRows);
  };

  const fetchAndDownloadTracker = async (trackerId) => {
    try {
      const response = await axios.get(`tracker/${trackerId}`, {
        withCredentials: true,
      });

      if (response.status === 200) {
        const url = response.data.url;
        window.open(url, "_blank", "noopener,noreferrer");
      }
    } catch {
      addToast(
        "Something went wrong when downloading the tracker. Please try again.",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  };

  const fetchTrackers = async () => {
    try {
      const response = await axios.get("tracker/list", {
        withCredentials: true,
      });

      if (response.status === 200) {
        parseAndSetRows(response.data);
      }
    } catch {
      addToast(
        "Something went wrong when loading trackers. Please try again.",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  };

  useEffect(() => {
    fetchTrackers();
  }, [trackersTableRefreshKey]);

  const columns = [
    {
      field: "trackerName",
      headerName: "Tracker",
      width: 200,
      renderCell: (params) => (
        <Link href="#" onClick={() => fetchAndDownloadTracker(params.row.id)}>
          {params.value}
        </Link>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      type: "singleSelect",
      valueOptions: ["Uploaded", "Indexed"],
    },
    {
      field: "uploadedBy",
      headerName: "Uploaded By",
      description: "User who uploaded the document",
      width: 200,
    },
    {
      field: "dateUploaded",
      headerName: "Date Uploaded",
      type: "date",
      width: 200,
    },
  ];

  return (
    <Card>
      {/* <PerfectScrollbar> */}
      <DataGrid
        rows={rows}
        columns={columns}
        rowSelectionModel={trackerListSelection}
        onRowSelectionModelChange={(newSelection) => {
          setTrackerListSelection(newSelection);
        }}
        // initialState={{
        //   pagination: {
        //     paginationModel: { page: 0, pageSize: 10 },
        //   },
        // }}
        // pageSizeOptions={[5, 10]}
        checkboxSelection
        disableSelectionOnClick
        hideFooterPagination
      />
      {/* </PerfectScrollbar> */}
    </Card>
  );
}
