import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Divider,
  TextField,
  makeStyles,
} from "@material-ui/core";
import axios from "axios";
import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";
import Page from "../../../../../misc/Page";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  mainContainer: {
    display: "flex",
    // justifyContent: "space-between",
    flexDirection: "column",
    alignContent: "center",
    justifyContent: "flex-start",
    width: "100%",
    rowGap: "10px",
  },
  cardFlexBox: {
    flex: 1,
    width: "100%",
    height: "100%",
  },
  searchContainer: {
    gap: "20px",
  },
  textInput: {
    width: "90%",
    marginRight: "12px",
    // borderStyle: "solid",
    borderWidth: "1px",
  },
}));

const Settings = () => {
  const { addToast } = useToasts();
  const classes = useStyles();
  const [currentPass, setCurrentPass] = useState("");
  const [newPass, setNewPass] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const handleSearchBarEnter = async (event) => {
    if (event.key === "Enter") {
      await doChangePassword();
    }
  };

  const doChangePassword = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        "change_password",
        {
          current_password: currentPass,
          new_password: newPass,
        },
        {
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        setIsLoading(false);
        addToast("Successfully changed password!", {
          appearance: "success",
          autoDismiss: true,
        });
      }
    } catch {
      addToast("Something went wrong. Please try again.", {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setIsLoading(false);
      resetAll();
    }
  };

  const handleCurrentPassChange = (event) => {
    setCurrentPass(event.target.value);
  };

  const handleNewPassChange = (event) => {
    setNewPass(event.target.value);
  };

  const resetAll = () => {
    setCurrentPass("");
    setNewPass("");
  };

  const loading = isLoading ? <CircularProgress /> : null;

  return (
    <Page className={classes.root} title="Search Documents">
      <Container className={classes.mainContainer}>
        <Card className={classes.cardFlexBox}>
          <CardHeader title="Change Password" />
          <CardContent>
            <Box display="flex" alignItems="center" flexDirection="row">
              <TextField
                className={classes.textInput}
                value={currentPass}
                name="currPass"
                variant="outlined"
                onChange={handleCurrentPassChange}
                placeholder="Current Password"
                size="small"
                onKeyDown={handleSearchBarEnter}
                type="password"
              />
              <TextField
                className={classes.textInput}
                value={newPass}
                name="newPass"
                variant="outlined"
                onChange={handleNewPassChange}
                placeholder="New Password"
                size="small"
                onKeyDown={handleSearchBarEnter}
                type="password"
              />
            </Box>
          </CardContent>
          <Divider />
          <Box display="flex" justifyContent="flex-end" p={2}>
            {loading}
            <Button
              disabled={!currentPass || !newPass}
              color="primary"
              variant="contained"
              onClick={doChangePassword}
            >
              Submit
            </Button>
          </Box>
        </Card>
      </Container>
    </Page>
  );
};

export default Settings;
