import { Divider } from "@material-ui/core";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import axios from "axios";
import React, { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const DocumentDisplay = () => {
  const [fileUrl, setFileUrl] = React.useState("");
  const { documentId } = useParams();
  const [searchParams] = useSearchParams();
  const pageNumber = searchParams.get("page");

  // const renderToolbar = (Toolbar) => (
  //   <Toolbar>
  //     {(slots) => {
  //       const {
  //         CurrentPageInput,
  //         Download,
  //         EnterFullScreen,
  //         GoToNextPage,
  //         GoToPreviousPage,
  //         NumberOfPages,
  //         Print,
  //         ShowSearchPopover,
  //         Zoom,
  //         ZoomIn,
  //         ZoomOut,
  //       } = slots;

  //       return (
  //         <div
  //           style={{
  //             alignItems: "center",
  //             display: "flex",
  //             width: "100%",
  //           }}
  //         >
  //           <div style={{ padding: "0px 2px" }}>
  //             <ShowSearchPopover />
  //           </div>
  //           <div style={{ padding: "0px 2px" }}>
  //             <ZoomOut />
  //           </div>
  //           <div style={{ padding: "0px 2px" }}>
  //             <Zoom />
  //           </div>
  //           <div style={{ padding: "0px 2px" }}>
  //             <ZoomIn />
  //           </div>
  //           <div style={{ padding: "0px 2px", marginLeft: "auto" }}>
  //             <GoToPreviousPage />
  //           </div>
  //           <div style={{ padding: "0px 2px", width: "3rem" }}>
  //             <CurrentPageInput />
  //           </div>
  //           <div style={{ padding: "0px 2px" }}>
  //             / <NumberOfPages />
  //           </div>
  //           <div style={{ padding: "0px 2px" }}>
  //             <GoToNextPage />
  //           </div>
  //           <div style={{ padding: "0px 2px", marginLeft: "auto" }}>
  //             <EnterFullScreen />
  //           </div>
  //           <div style={{ padding: "0px 2px" }}>
  //             <Download />
  //           </div>
  //           <div style={{ padding: "0px 2px" }}>
  //             <Print />
  //           </div>
  //         </div>
  //       );
  //     }}
  //   </Toolbar>
  // );

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (_) => [],
    // renderToolbar,
  });

  const fetchDocument = async () => {
    try {
      const response = await axios.get(`document/${documentId}`, {
        withCredentials: true,
      });

      if (response.status === 200) {
        setFileUrl(response.data.url);
      }
    } catch {
      setFileUrl("error");
    }
  };

  function displayDocLoadError() {
    return <div>There was an error loading the document.</div>;
  }

  useEffect(() => {
    fetchDocument();
  }, []);

  return (
    <div>
      <Divider />
      <div
        style={{
          border: "1px solid rgba(0, 0, 0, 0.3)",
          height: "95vh",
        }}
      >
        <Worker workerUrl="/pdf.worker.min.3.4.120.js">
          {fileUrl && (
            <Viewer
              fileUrl={fileUrl}
              renderError={displayDocLoadError}
              plugins={[defaultLayoutPluginInstance]}
              initialPage={pageNumber - 1}
            />
          )}
        </Worker>
      </div>
    </div>
  );
};

export default DocumentDisplay;
