import {
  Box,
  Card,
  CardContent,
  Divider,
  IconButton,
  Link,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import {
  ChevronDown as ChevronDownIcon,
  ChevronRight as ChevronRightIcon,
} from "react-feather";
import { Document, Thumbnail } from "react-pdf";
import { useToasts } from "react-toast-notifications";

function highlightPattern(text, pattern) {
  return text.replace(pattern, (value) => `<mark>${value}</mark>`);
}

function limitStringToCharacters(inputString, limit) {
  if (inputString.length <= limit) {
    return inputString;
  } else {
    return inputString.slice(0, limit) + "...";
  }
}

const useStyles = makeStyles((theme) => ({
  cardFileTitle: {
    display: "flex",
    // justifyContent: "space-between",
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "space-between",
    alignItems: "center",
  },
  sourceTitleRightEnd: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));

const SourceCard = (props) => {
  const {
    className,
    fileUrl,
    fileName,
    filePage,
    relevantText,
    prestDocId,
    fileType,
    sheetName,
  } = props;
  const [showDocumentPreview, setShowDocumentPreview] = React.useState(false);
  const classes = useStyles();
  const { addToast } = useToasts();

  const bodyText = limitStringToCharacters(
    relevantText.split(/\r\n|\r|\n/g).join(""),
    1000
  );

  const showDocumentPreviewLoadFailedToast = () => {
    addToast(
      "Something went wrong when loading document preview. Please try again.",
      {
        appearance: "error",
        autoDismiss: true,
      }
    );
  };

  const toggleDocumentPreview = (e) => {
    if (fileType === "XLSX") return;
    setShowDocumentPreview(!showDocumentPreview);
    e.stopPropagation();
  };

  const buildFileUrlFor = (docId, pageNum) => {
    const url = `/admin/documents/${docId}?page=${pageNum + 1}`;
    return window.location.origin + url;
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <div>
      <Card className={className}>
        <CardContent
          className={classes.cardFileTitle}
          onClick={toggleDocumentPreview}
        >
          <Link
            href={
              fileType === "PDF"
                ? buildFileUrlFor(prestDocId, filePage)
                : fileUrl
            }
            onClick={(e) => e.stopPropagation()}
            target="_blank"
            rel="noopener"
            color="primary"
          >
            {fileName}
          </Link>
          <Box className={classes.sourceTitleRightEnd}>
            <h3>{fileType === "PDF" ? "Page " + (filePage + 1) : sheetName}</h3>
            <IconButton onClick={toggleDocumentPreview}>
              {showDocumentPreview ? (
                <ChevronDownIcon size={18} />
              ) : (
                <ChevronRightIcon size={18} />
              )}
            </IconButton>
          </Box>
        </CardContent>
        <Divider />
        {fileType === "PDF" && (
          <CardContent>
            <div>{bodyText}</div>
          </CardContent>
        )}
      </Card>
      <Card>
        {showDocumentPreview && (
          <Document
            file={fileUrl}
            onLoadError={() => showDocumentPreviewLoadFailedToast()}
            onSourceError={() => showDocumentPreviewLoadFailedToast()}
            onClick={() => openInNewTab(buildFileUrlFor(prestDocId, filePage))}
          >
            <Thumbnail pageNumber={parseInt(filePage) + 1} />
          </Document>
        )}
      </Card>
    </div>
  );
};

export default SourceCard;
