import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  makeStyles,
  Toolbar,
} from "@material-ui/core";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import MenuIcon from "@material-ui/icons/Menu";
import clsx from "clsx";
import React, { useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import { AuthContext } from "../../../../../../context/AuthContext";
import ClubLogo from "../../../../../../images/prest-logo-long.png";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#ffffff",
    borderBottom: "1px solid #e5e7eb",
  },
  avatar: {
    width: 60,
    height: 60,
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const { logoutAdmin } = useContext(AuthContext);

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Toolbar>
        <RouterLink to="/admin/search">
          <img style={{ height: "60px", width: "110px" }} src={ClubLogo} />
        </RouterLink>
        <Box flexGrow={1} />
        <IconButton onClick={() => logoutAdmin()} color="primary">
          <LogoutIcon />
        </IconButton>
        <Hidden lgUp>
          <IconButton color="primary" onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

// TopBar.propTypes = {
//   className: PropTypes.string,
//   onMobileNavOpen: PropTypes.func,
// };

export default TopBar;
