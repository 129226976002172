import Cookies from "js-cookie";
import * as React from "react";
import { useState } from "react";

export const AuthContext = React.createContext({
  loginAdmin: () => {},
  logoutAdmin: () => {},
  isAdminAuth: true,
});

const AuthContextProvider = (props) => {
  const adminCookieName = `admin_logged_in`;
  const userTokenCookieName = `user_auth_token`;
  const adminCookieExists = Cookies.get(adminCookieName) != undefined;

  const [isAdminAuth, setIsAdminAuth] = useState(adminCookieExists);

  const adminLoginHandler = (authToken) => {
    setIsAdminAuth(true);
    Cookies.set(adminCookieName, "on", {
      expires: 365,
      path: "/",
      // TODO: update to  process.env.NODE_ENV !== "development",
      secure: false,
    });
    Cookies.set(userTokenCookieName, authToken, {
      expires: 30,
      path: "/",
      // TODO: update to  process.env.NODE_ENV !== "development",
      secure: false,
    });
  };

  const adminLogoutHandler = () => {
    setIsAdminAuth(false);
    Cookies.remove(adminCookieName);
    Cookies.remove(userTokenCookieName);
  };

  return (
    <AuthContext.Provider
      value={{
        loginAdmin: adminLoginHandler,
        logoutAdmin: adminLogoutHandler,
        isAdminAuth: isAdminAuth,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
