import {
  Box,
  Button,
  Card,
  CardHeader,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  makeStyles,
  styled,
} from "@material-ui/core";
import axios from "axios";
import React, { useState } from "react";
import { X as XIcon } from "react-feather";
import { useToasts } from "react-toast-notifications";
import convertMIMEToDocumentType from "../../../../../../util/convertMIMEToDocumentType";
import StyledDropzone from "../../../../../StyledDropzone/StyledDropzone.js";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  postContainer: {
    gap: "20px",
  },
  textInput: {
    width: "25%",
    // borderStyle: "solid",
    borderWidth: "1px",
  },
  uploadCard: {
    overflowY: "scroll",
  },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <XIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const DocumentUpload = (props) => {
  const {
    documentUploadOpen,
    handleDocumentUploadClose,
    setDocumentsTableRefreshKey,
  } = props;

  const { addToast } = useToasts();
  const classes = useStyles();
  const [documentNames, setDocumentNames] = useState([]);
  const [documentKeys, setDocumentKeys] = useState([]);
  const [documentTypes, setDocumentTypes] = useState({});
  const [totalDocumentsToUpload, setTotalDocumentsToUpload] = useState(-1);
  const [autoIndex, setAutoIndex] = useState(true);

  const createDocument = async () => {
    try {
      const response = await axios.post(
        "document/create",
        {
          documents: documentNames.map((docName, index) => {
            return {
              name: docName,
              key: documentKeys[index],
              type: convertMIMEToDocumentType(documentTypes[docName]),
            };
          }),
          should_index: autoIndex,
        },
        {
          withCredentials: true,
        }
      );

      if (response.status === 201) {
        addToast(
          autoIndex
            ? "Successfully uploaded documents. They will be indexed shortly!"
            : "Successfully uploaded documents.",
          {
            appearance: "success",
            autoDismiss: true,
          }
        );
        setDocumentsTableRefreshKey((oldKey) => oldKey + 1);
      }
    } catch {
      addToast(
        "Something went wrong when uploading document. Please try again.",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
    resetAll();
  };

  const documentRejected = () => {
    addToast(
      `You can only upload 25 files (PDF or XLSX) at once. The size of each must be less than 25MB.`,
      {
        appearance: "error",
      }
    );
    resetAll();
  };

  const handleAutoIndexCheckboxChange = (event) => {
    setAutoIndex(event.target.checked);
  };

  const resetAll = () => {
    setDocumentKeys([]);
    setDocumentNames([]);
    setDocumentTypes({});
    handleDocumentUploadClose();
  };

  const postButtonEnabled =
    totalDocumentsToUpload === 0 &&
    documentNames.length > 0 &&
    documentKeys.length > 0;

  return (
    <BootstrapDialog
      onClose={handleDocumentUploadClose}
      aria-labelledby="customized-dialog-title"
      open={documentUploadOpen}
      maxWidth="lg"
    >
      <Card className={classes.uploadCard}>
        <CardHeader
          subheader="Upload a new document to make it searchable :)"
          title="Upload Document"
        />
        <DialogContent dividers>
          <Box
            display="flex"
            flexDirection="column"
            className={classes.postContainer}
          >
            <StyledDropzone
              fileType={{
                "file/pdf": [".pdf", ".xlsx"],
              }}
              onFileDropRejected={documentRejected}
              maxSize={25000000}
              maxFiles={25}
              setUploadedMediaKeys={setDocumentKeys}
              selectText="Drag 'n' drop your file here, or click to select a file"
              currFileNames={documentNames}
              setCurrFileNames={setDocumentNames}
              handleDocumentUploadClose={handleDocumentUploadClose}
              setTotalDocumentsToUpload={setTotalDocumentsToUpload}
              setFileTypes={setDocumentTypes}
            />
          </Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={autoIndex}
                onChange={handleAutoIndexCheckboxChange}
              />
            }
            label="Automatically index documents"
          />
        </DialogContent>
        <DialogActions display="flex" justifyContent="flex-end" p={2}>
          <Button
            disabled={!postButtonEnabled}
            color="primary"
            variant="contained"
            onClick={createDocument}
          >
            Upload
          </Button>
        </DialogActions>
      </Card>
    </BootstrapDialog>
  );
};

export default DocumentUpload;
