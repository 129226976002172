import { Button, Card, makeStyles } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const docStatusToDisplay = (status) => {
  switch (status) {
    case "UPLOADED":
      return "Uploaded";
    case "INDEXED":
      return "Indexed";
    case "INDEXING":
      return "Indexing...";
    default:
      return "Uploaded";
  }
};

const PAGE_SIZE = 50;

export default function DocumentList(props) {
  const {
    documentListSelection,
    setDocumentListSelection,
    documentsTableRefreshKey,
    setDocumentsTableRefreshKey,
    documentSearchTerm,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const { addToast } = useToasts();

  const mapPageToNextCursor = React.useRef({
    0: 0,
  });
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: PAGE_SIZE,
  });
  const [totalDocuments, setTotalDocuments] = React.useState(0);

  const handlePaginationModelChange = (newPaginationModel) => {
    // We have the cursor, we can allow the page transition.
    console.log(newPaginationModel);
    console.log(mapPageToNextCursor);
    if (
      newPaginationModel.page == 0 ||
      mapPageToNextCursor.current[newPaginationModel.page]
    ) {
      setPaginationModel(newPaginationModel);
    }
  };

  const parseAndSetRows = (data) => {
    const parsedRows = data.documents.map((document) => {
      return {
        id: document.id,
        documentName: document.name,
        status: docStatusToDisplay(document.status),
        uploadedBy: document.created_by_user.email,
        dateUploaded: new Date(document.created_at),
      };
    });
    setRows(parsedRows);
  };

  const fetchDocuments = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        "document/list",
        {
          params: {
            search: documentSearchTerm,
            cursor: mapPageToNextCursor.current[paginationModel.page],
            limit: PAGE_SIZE,
          },
        },
        {
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        parseAndSetRows(response.data);
        setTotalDocuments(
          (prevTotalDocuments) =>
            response.data.pagination?.total_count ?? prevTotalDocuments
        );
        mapPageToNextCursor.current[paginationModel.page + 1] =
          response.data.pagination?.next_cursor;
      }
    } catch {
      addToast(
        "Something went wrong when loading documents. Please try again.",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, [documentsTableRefreshKey, paginationModel.page, documentSearchTerm]);

  const indexDocument = async (document_id, document_name) => {
    try {
      setIsLoading(true);
      const response = await axios.put(
        "document/index",
        {
          document_id: document_id,
        },
        {
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        addToast(
          `We are working on indexing ${document_name}. It will be available for search shortly!`,
          {
            appearance: "success",
            autoDismiss: true,
          }
        );
        setDocumentsTableRefreshKey((oldKey) => oldKey + 1);
      }
    } catch {
      addToast(
        `Something went wrong when indexing ${document_name}. Please try again.`,
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    { field: "documentName", headerName: "Document", width: 200 },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      type: "singleSelect",
      valueOptions: ["Uploaded", "Indexed"],
    },
    {
      field: "uploadedBy",
      headerName: "Uploaded By",
      description: "User who uploaded the document",
      width: 200,
    },
    {
      field: "dateUploaded",
      headerName: "Date Uploaded",
      type: "date",
      width: 200,
    },
    {
      field: "actions",
      type: "actions",
      sortable: false,
      width: 80,
      getActions: (params) => [
        <Button
          key={params.row.id}
          disabled={params.row.status != "Uploaded" || isLoading}
          color="primary"
          onClick={() => {
            indexDocument(params.row.id, params.row.documentName);
          }}
        >
          Index
        </Button>,
      ],
      disableClickEventBubbling: true,
    },
  ];

  return (
    <Card>
      {/* <PerfectScrollbar> */}
      <DataGrid
        loading={isLoading}
        rows={rows}
        columns={columns}
        rowSelectionModel={documentListSelection}
        onRowSelectionModelChange={(newSelection) => {
          setDocumentListSelection(newSelection);
        }}
        rowCount={totalDocuments}
        pageSizeOptions={[PAGE_SIZE]}
        checkboxSelection
        disableSelectionOnClick
        paginationMode="server"
        onPaginationModelChange={handlePaginationModelChange}
        paginationModel={paginationModel}
      />
      {/* </PerfectScrollbar> */}
    </Card>
  );
}
