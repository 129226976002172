export default (file = "", appendDate = true) => {
  const fileType = file.split(".").pop();
  const fileNameArr = file.split(".");
  fileNameArr.pop();
  // remove spaces from filename
  let fileName = fileNameArr.join("").split(" ").join("");
  // remove invalid s3 characters
  const regex = /[^0-9a-zA-Z!\-_.*'() ]/g;
  const newFileName = fileName.replace(regex, "");
  if (appendDate) {
    return newFileName + "_" + Date.now() + "." + fileType;
  } else {
    return newFileName + "." + fileType;
  }
};
