import axios from "axios";
import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";
import styled from "styled-components";
import tw from "twin.macro";
import { PrimaryButton as PrimaryButtonBase } from "../../../misc/Buttons.js";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "../../../misc/Headings.js";

const TextContent = tw.div`lg:py-8 text-center md:text-center`;

const Subheading = tw(SubheadingBase)`mb-10 text-center`;
const Heading = tw(
  SectionHeading
)`font-black text-center text-3xl sm:text-4xl lg:text-5xl text-center md:text-center leading-tight`;
const Description = tw.p`mt-4 text-center md:text-center text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto`;
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;
const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`h-24`}
`;

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`;

export default ({
  subheading = "CONTACT US",
  heading = <>Want to hear more?</>,
  description = "Fill out your information below and we will reach out to you within 24 hours for a personalized demo!",
  submitButtonText = "Submit",
}) => {
  const { addToast } = useToasts();
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [name, setName] = useState("");
  const [text, setText] = useState("");

  const somethingWentWrong = () => {
    // console.log("wrong");
    addToast("Something went wrong. Please try again.", {
      appearance: "error",
      autoDismiss: true,
    });
  };

  const sendContactUsQuery = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        "company/contact_us",
        {
          email: email,
          full_name: name,
        },
        {
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        addToast("Thanks! We'll email you within 24 hours :)", {
          appearance: "success",
          autoDismiss: true,
        });
        resetAll();
      } else {
        somethingWentWrong();
      }
    } catch {
      somethingWentWrong();
    }
  };

  const resetAll = () => {
    setEmail("");
    setSubject("");
    setName("");
    setText("");
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
  };

  return (
    <TextContent>
      {subheading && <Subheading>{subheading}</Subheading>}
      <Heading>{heading}</Heading>
      {description && <Description>{description}</Description>}
      <Form onSubmit="return false">
        <Input
          type="email"
          required
          name="email"
          placeholder="Your Email Address"
          value={email}
          onChange={handleEmailChange}
        />
        <Input
          type="text"
          required
          name="name"
          placeholder="Full Name"
          value={name}
          onChange={handleNameChange}
        />
        {/* <Input
          type="text"
          required
          name="subject"
          placeholder="Subject"
          value={subject}
          onChange={handleSubjectChange}
        />
        <Textarea
          required
          name="message"
          placeholder="Your Message Here"
          value={text}
          onChange={handleTextChange}
        /> */}
        <SubmitButton onClick={sendContactUsQuery}>
          {submitButtonText}
        </SubmitButton>
      </Form>
    </TextContent>
  );
};
