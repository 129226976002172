import { colors, createTheme } from "@material-ui/core";
import shadows from "./shadows";
import typography from "./typography";

const theme = createTheme({
  palette: {
    background: {
      dark: "#F4F6F8",
      default: colors.common.white,
      paper: colors.common.white,
    },
    primary: {
      main: "#5271ff",
    },
    secondary: {
      main: "#5271ff",
    },
    text: {
      primary: "#000000",
      secondary: colors.blueGrey[900],
    },
  },
  shadows,
  typography,
});

export default theme;
